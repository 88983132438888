<script setup lang="ts">
import { AppButton, AppFormCard, AppFormHeader, AppTextField, AppTypography } from '@/core/components';
import { DocumentType } from '@/core/enums/documentType';
import { useAuthStore } from '@/modules/auth/store/auth';
import type { User as IUser } from '@/modules/auth/types/Auth';
import requiredDocumentService from '@/services/RequiredDocumentService';
import type { RequiredDocument as IRequiredDocument } from '@/types/RequiredDocument';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    isOnboarding?: boolean;
  }>(),
  {
    title: 'ABN',
    description: '',

    isOnboarding: true,
  }
);

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'next', next: string): void;
  (e: 'skip', next: string): void;
}>();

const { VALIDATIONS } = useValidation();
const { form, handleSubmit, getErrors, v$ } = useForm<IRequiredDocument>({
  form: {
    abn: '',
    document_type: DocumentType.ABN,
  },
  rules: {
    abn: {
      required: VALIDATIONS.required('ABN'),
      abn: VALIDATIONS.abn(),
    },
  },
});

const abnOption = {
  mask: '###########',
};
const { user, setUser, isAbnValid } = useAuthStore();
const pending = ref(false);
const requiredDocumentItems = ref<IRequiredDocument[]>([]);
const { showSnackbar } = useSnackbar();

const abnDocument = computed((): IRequiredDocument => {
  requiredDocumentItems.value = user.documents as IRequiredDocument[];
  return requiredDocumentItems.value
    ? requiredDocumentItems.value.find((item) => item.document_type === DocumentType.ABN)
    : null;
});

const isDisabled = computed((): boolean => {
  return (
    isAbnValid?.value ||
    !form.value.abn ||
    (!props.isOnboarding && abnDocument.value ? form.value.abn === abnDocument.value?.abn : false)
  );
});

function initialize() {
  if (abnDocument.value) {
    form.value.abn = abnDocument.value.abn;
  }
}

const submit = async (val: any) => {
  pending.value = true;

  await handleSubmit(async function () {
    const { data, error } = await requiredDocumentService.post({
      ...form.value,
      abn: form.value.abn,
    });
    requiredDocumentItems.value = data.value as IRequiredDocument[];
    if (!error.value) {
      setUser(data.value?.data as IUser);

      if (props.isOnboarding) {
        emits('next', 'required-documents');
      } else {
        showSnackbar({
          text: 'Your ABN has been updated successfully!',
          state: true,
        });

        emits('success');
      }
    }
  });

  pending.value = false;
};

onMounted(() => {
  nextTick(() => {
    initialize();
  });
});

defineExpose({
  submit,
  pending,
  isDisabled,
});
</script>

<template>
  <div class="abn">
    <AppFormHeader
      v-if="!isOnboarding"
      :title="title"
      :description="description"
      :loading="pending || isDisabled"
      :show-button="!isAbnValid"
      class="mb-5"
      @submit="submit"
    />

    <v-form class="w-100" @submit.prevent="submit(form.abn)">
      <AppFormCard max-width="820" title="ABN">
        <AppTypography element="h3" type="body-1" class="mt-2 mb-2">
          SupportApp requires an Australian Business Number (ABN) to approve you on the platform. You will be operating
          as an independent contractor. Your ABN must be either:
        </AppTypography>
        <ul class="bullet-list">
          <li>
            <AppTypography element="span" type="body-1">A sole trader type in your name</AppTypography>
          </li>
          <li>
            <AppTypography element="span" type="body-1"
              >An incorporated entity of which you are a director</AppTypography
            >
          </li>
        </ul>
        <AppTypography element="h3" type="subheadline-1" class="mt-6 mb-2 ml-1">ABN</AppTypography>
        <AppTextField
          v-model="form.abn"
          :maska-option="abnOption"
          :disabled="pending"
          :readonly="isAbnValid"
          title="ABN"
          persistent-hint
          hint="ABN must be 11 digits long"
          :error-messages="getErrors().abn"
        />
      </AppFormCard>
      <AppButton v-if="isOnboarding" block :disabled="isDisabled" :loading="pending" class="mt-5">Save</AppButton>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.abn {
  width: 100%;
  max-width: var(--form-max-width);
}

:deep(.v-field__input) {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.bullet-list {
  list-style-type: disc;
  padding-left: 24px; /* Adjust as needed */
}

.bullet-list li {
  margin-bottom: 8px; /* Adjust spacing between items */
}
</style>

<script setup lang="ts">
import { AppButton, AppFormCard, AppFormHeader } from '@/core/components';
import { DocumentType } from '@/core/enums/documentType';
import { useAuthStore } from '@/modules/auth/store/auth';
import { storeToRefs } from 'pinia';
import RequiredDocumentForm from './RequiredDocumentForm.vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    isOnboarding?: boolean;
  }>(),
  {
    title: 'Police Check',
    description: '',

    isOnboarding: true,
  }
);

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'next', next: string): void;
  (e: 'skip', next: string): void;
}>();

const pending = ref(false);
const policeCheckForm = ref();
const { user } = storeToRefs(useAuthStore());
const { showSnackbar } = useSnackbar();

const isDisabled = computed((): boolean => {
  return policeCheckForm.value ? policeCheckForm.value.isDisabled : true;
});

const submit = async () => {
  let success = false;

  if (policeCheckForm.value) {
    success = await policeCheckForm.value.handleSubmit();
  }

  if (success) {
    showSnackbar({ text: 'Your police check has been updated successfully!', state: true });

    if (props.isOnboarding) {
      emits('next', 'required-documents');
    } else {
      emits('success');
    }
  }
};

async function handleNewPoliceCheck() {
  await navigateTo('https://supportapp.checked.com.au/', {
    open: {
      target: '_blank',
    },
  });
  emits('next', 'required-documents');
}

defineExpose({
  submit,
  pending,
  isDisabled,
});
</script>

<template>
  <div class="police-check">
    <AppFormHeader
      v-if="!isOnboarding"
      :title="title"
      :description="description"
      :loading="pending"
      :show-button="false"
      class="mb-5"
      @submit="submit"
    />

    <v-form @submit.prevent="submit">
      <AppFormCard
        title="Police Check"
        subtitle="Upload an existing and valid Police Check or complete a new police check with our secure partner. Our partner will charge you accordingly for your new police check. For existing police checks, they must have been issued within the last 90 days."
        class="mt-4"
        no-gutter
      >
        <RequiredDocumentForm
          ref="policeCheckForm"
          :document-type="DocumentType.POLICE_CHECKS"
          @pending="pending = $event"
        />
      </AppFormCard>

      <AppButton block color="primary" class="mt-4" type="button" variant="text" @click="handleNewPoliceCheck">
        New Police Check
      </AppButton>

      <AppButton block color="primary" class="mt-4" rounded="xl" :loading="pending" :disabled="isDisabled">
        Upload Existing Police Check
      </AppButton>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.police-check {
  width: 100%;
  max-width: var(--form-max-width);
}

.inner-card {
  border: 1px solid #e0e0e0;
}

:deep(.app-form-header) {
  align-items: start !important;
}
</style>

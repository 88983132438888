<script setup lang="ts">
import { AppButton, AppFormCard, AppFormHeader } from '@/core/components';
import { DocumentType } from '@/core/enums/documentType';
import RequiredDocumentForm from './RequiredDocumentForm.vue';
import { useAuthStore } from '@/modules/auth/store/auth';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    isOnboarding?: boolean;
  }>(),
  {
    title: 'First Aid & CPR',
    description: '',

    isOnboarding: true,
  }
);

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'next', next: string): void;
  (e: 'skip', next: string): void;
}>();

const firstAidForm = ref();
const cprForm = ref();
const pending = ref(false);
const { isFirstAidValid } = useAuthStore();
const { showSnackbar } = useSnackbar();

const isDisabled = computed((): boolean => {
  if (isFirstAidValid?.value || !firstAidForm.value || !cprForm.value) return true;

  return (
    firstAidForm.value.isDisabled ||
    cprForm.value.isDisabled ||
    (props.isOnboarding ? false : !firstAidForm.value.hasChanges && !cprForm.value.hasChanges)
  );
});

const submit = async () => {
  let success = false;

  if (firstAidForm.value) {
    success = await firstAidForm.value.handleSubmit();
  }

  if (cprForm.value) {
    success = await cprForm.value.handleSubmit();
  }

  if (success) {
    if (props.isOnboarding) {
      emits('next', 'required-documents');
    } else {
      showSnackbar({ text: 'First Aid & CPR documents submitted successfully', state: true });

      emits('success');
    }
  }
};

defineExpose({
  submit,
  pending,
  isDisabled,
});
</script>

<template>
  <div class="first-aid-cpr">
    <AppFormHeader
      v-if="!isOnboarding"
      :title="title"
      :description="description"
      :loading="pending || isDisabled"
      :show-button="!isFirstAidValid"
      :class="title ? 'mb-5' : ''"
      @submit="submit"
    />

    <v-form @submit.prevent="submit">
      <AppFormCard
        title="First Aid & CPR"
        subtitle="Upload your documents for First Aid & CPR training for verification"
        :class="title ? 'mt-4' : ''"
        no-gutter
      >
        <RequiredDocumentForm
          ref="firstAidForm"
          title="First Aid"
          issue-date-label="Issue Date"
          :document-type="DocumentType.FIRST_AID"
          :readonly="isFirstAidValid"
          @pending="pending = $event"
        />

        <RequiredDocumentForm
          ref="cprForm"
          title="CPR"
          issue-date-label="Issue Date"
          :document-type="DocumentType.CPR_CERTIFICATE"
          :readonly="isFirstAidValid"
          @pending="pending = $event"
        />
      </AppFormCard>

      <AppButton
        v-if="isOnboarding"
        block
        color="primary"
        class="mt-4"
        rounded="xl"
        :disabled="isDisabled"
        :loading="pending"
      >
        Save
      </AppButton>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.first-aid-cpr {
  width: 100%;
  max-width: var(--form-max-width);
}

.inner-card {
  border: 1px solid #e0e0e0;
}
</style>

<script setup lang="ts">
import { AppButton, AppFormCard, AppFormHeader } from '@/core/components';
import RequiredDocumentForm from './RequiredDocumentForm.vue';
import { DocumentType } from '@/core/enums/documentType';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    isOnboarding?: boolean;
  }>(),
  {
    title: 'COVID-19 Vaccination',
    description: '',

    isOnboarding: true,
  }
);

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'next', next: string): void;
  (e: 'skip', next: string): void;
}>();

const pending = ref(false);
const covidVaccinationForm = ref();
const { showSnackbar } = useSnackbar();

const isDisabled = computed((): boolean => {
  if (!covidVaccinationForm.value) return true;

  return covidVaccinationForm.value.isDisabled || (props.isOnboarding ? false : !covidVaccinationForm.value.hasChanges);
});

const submit = async () => {
  let success = false;

  if (covidVaccinationForm.value) {
    success = await covidVaccinationForm.value.handleSubmit();
  }

  if (success) {
    if (props.isOnboarding) {
      emits('next', 'required-documents');
    } else {
      showSnackbar({ text: 'COVID-19 Vaccination saved successfully', state: true });

      emits('success');
    }
  }
};

defineExpose({
  submit,
  pending,
  isDisabled,
});
</script>

<template>
  <div class="covid-vaccination">
    <AppFormHeader
      v-if="!isOnboarding"
      :title="title"
      :description="description"
      :loading="pending || isDisabled"
      class="mb-5"
      @submit="submit"
    />

    <v-form @submit.prevent="submit">
      <AppFormCard
        title="COVID-19 Vaccination"
        subtitle="Upload your COVID-19 Vaccination and date of issue"
        class="mt-4"
        no-gutter
      >
        <RequiredDocumentForm
          ref="covidVaccinationForm"
          :document-type="DocumentType.COVID_VACCINATION"
          no-border
          @pending="pending = $event"
        />
      </AppFormCard>

      <AppButton
        v-if="isOnboarding"
        block
        color="primary"
        class="mt-4"
        rounded="xl"
        :disabled="isDisabled"
        :loading="pending"
      >
        Save
      </AppButton>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.covid-vaccination {
  width: 100%;
  max-width: var(--form-max-width);
}

.inner-card {
  border: 1px solid #e0e0e0;
}
</style>

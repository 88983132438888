<script setup lang="ts">
import {
  AppButton,
  AppCustomDatePicker,
  AppFileUpload,
  AppFormCard,
  AppTextField,
  AppTypography,
  AppFormHeader,
} from '@/core/components';
import { DocumentType } from '@/core/enums/documentType';
import { useAuthStore } from '@/modules/auth/store/auth';
import type { User as IUser } from '@/modules/auth/types/Auth';
import requiredDocumentService from '@/services/RequiredDocumentService';
import type { RequiredDocument } from '@/types/RequiredDocument';
import dayjs from 'dayjs';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    isOnboarding?: boolean;
  }>(),
  {
    title: 'Working With Children',
    description: '',

    isOnboarding: true,
  }
);

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'next', next: string): void;
  (e: 'skip', next: string): void;
}>();

const { VALIDATIONS } = useValidation();
const { form, handleSubmit, getErrors } = useForm<RequiredDocument>({
  form: {
    document_type: DocumentType.WWC,
    reference_number: '',
    expiry_date: '',
    media: [],
    attachment: null,
  },
  rules: {
    reference_number: {
      maxLength: VALIDATIONS.maxLength('Reference number', 20),
    },
    expiry_date: {
      date: VALIDATIONS.dateRequired('Expiry date'),
      minDate: VALIDATIONS.minDate('Expiry date', dayjs().subtract(5, 'year').format()),
      maxDate: VALIDATIONS.maxDate('Expiry date', dayjs().add(5, 'year').format()),
    },
  },
});
const referenceMaskOption = {
  mask: '********************',
};
const { user, setUser, isWwcValid, WWCTitle } = useAuthStore();
const requiredDocumentItems = ref<RequiredDocument[]>([]);
const { data, error: serverError, execute: execSubmit, pending } = useSubmit();
const { showSnackbar } = useSnackbar();

const wwcDocument = computed((): IRequiredDocument => {
  return requiredDocumentItems.value
    ? requiredDocumentItems.value.find((item) => item.document_type === DocumentType.WWC)
    : null;
});

const wwcDocumentMediaId = computed((): IRequiredDocument => {
  return wwcDocument.value && wwcDocument.value?.media?.length ? wwcDocument.value.media[0].id : undefined;
});

const errors = computed(() => {
  return getErrors(serverError.value?.data?.errors);
});

const isDisabled = computed((): boolean => {
  return (
    isWwcValid?.value ||
    !form.value.reference_number ||
    !form.value.expiry_date ||
    (!props.isOnboarding && wwcDocument.value
      ? form.value.reference_number === wwcDocument.value.reference_number &&
        dayjs(form.value.expiry_date).isSame(dayjs(wwcDocument.value.expiry_date), 'day') &&
        JSON.stringify([form.value.media]) === JSON.stringify(wwcDocument.value.media.map((a) => a.id).sort())
      : false)
  );
});

async function initialize(): Promise<void> {
  if (wwcDocument.value) {
    const media = Array.isArray(wwcDocument.value.media) ? wwcDocument.value.media[0] : null;

    form.value.reference_number = wwcDocument.value.reference_number;
    form.value.expiry_date = wwcDocument.value.expiry_date;
    form.value.media = media?.id;
    form.value.attachment = media;
  }
}

async function fetchWwcDocument(): Promise<void> {
  const { data } = await requiredDocumentService.get();
  requiredDocumentItems.value = data.value as RequiredDocument[];
}

async function submit(): Promise<void> {
  const payload = JSON.parse(JSON.stringify(form.value)) as IRequiredDocument;
  const media = form.value.media;

  if (media === wwcDocumentMediaId.value) {
    payload.media = [];
  } else {
    payload.media = [media];
  }

  handleSubmit(async () => {
    await execSubmit('required-documents', {
      method: 'POST',
      body: payload,
    });

    if (!serverError.value) {
      setUser(data.value?.data as IUser);
      requiredDocumentItems.value = data.value?.data?.documents;
      initialize();

      if (props.isOnboarding) {
        emits('next', 'required-documents');
      } else {
        showSnackbar({ text: 'Working with Children documents submitted successfully', state: true });

        emits('success');
      }
    }
  });
}

onMounted(() => {
  nextTick(async () => {
    await fetchWwcDocument();
    await initialize();
  });
});

defineExpose({
  submit,
  pending,
  isDisabled,
});
</script>

<template>
  <div class="working-with-children">
    <AppFormHeader
      v-if="!isOnboarding"
      :title="title"
      :description="description"
      :loading="pending || isDisabled"
      :show-button="!isWwcValid"
      class="mb-5"
      @submit="submit()"
    />

    <v-form @submit.prevent="submit">
      <AppFormCard
        :title="WWCTitle"
        subtitle="To provide services for clients under 18 years old, you must have a current clearance for working with children under the age of 18 in your state."
        class="mt-4"
        no-gutter
      >
        <div class="d-flex flex-column pa-0 my-4">
          <AppTypography type="subheadline-1" class="my-2">Reference Number</AppTypography>
          <AppTextField
            v-model="form.reference_number"
            v-maska:[referenceMaskOption]
            :readonly="isWwcValid"
            :error-messages="errors.reference_number"
            hide-details="auto"
          />
        </div>

        <AppCustomDatePicker
          v-model="form.expiry_date"
          :is-before="false"
          :readonly="isWwcValid"
          :error-messages="errors.expiry_date"
          class="pa-0 my-4"
          label="Expiry Date"
        />

        <div class="d-flex flex-column pa-0 mt-4">
          <AppTypography type="subheadline-1" class="my-2">Upload File</AppTypography>
          <AppFileUpload
            :file-types="[
              'image/jpg',
              'image/jpeg',
              'image/png',
              'image/heic',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]"
            v-model="form.media"
            :value="form.attachment"
            :readonly="isWwcValid"
            :error-messages="errors.media"
          />
        </div>
      </AppFormCard>

      <AppButton
        :disabled="isDisabled"
        v-if="isOnboarding"
        block
        color="primary"
        class="mt-4"
        rounded="xl"
        :loading="pending"
      >
        Save
      </AppButton>
    </v-form>
  </div>
</template>
<style lang="scss" scoped>
.working-with-children {
  width: 100%;
  max-width: var(--form-max-width);
}
</style>

<script setup lang="ts">
import { AppButton, AppFormCard, AppFormHeader } from '@/core/components';
import RequiredDocumentForm from './RequiredDocumentForm.vue';
import { DocumentType } from '@/core/enums/documentType';
import { useAuthStore } from '@/modules/auth/store/auth';
import { storeToRefs } from 'pinia';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    isOnboarding?: boolean;
  }>(),
  {
    title: 'Public Liability',
    description: '',

    isOnboarding: true,
  }
);

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'next', next: string): void;
  (e: 'skip', next: string): void;
}>();

const { isPublicLiabilityValid } = useAuthStore();
const pending = ref(false);
const publicLiabilityForm = ref();
const { showSnackbar } = useSnackbar();

const isDisabled = computed((): boolean => {
  if (isPublicLiabilityValid?.value || !publicLiabilityForm.value) return true;

  return publicLiabilityForm.value.isDisabled || (props.isOnboarding ? false : !publicLiabilityForm.value.hasChanges);
});

const submit = async () => {
  let success = false;

  if (publicLiabilityForm.value) {
    success = await publicLiabilityForm.value.handleSubmit();
  }

  if (success) {
    showSnackbar({ text: 'Public Liability & Indemnity Insurance documents submitted successfully', state: true });

    if (props.isOnboarding) {
      emits('next', 'required-documents');
    } else {
      emits('success');
    }
  }
};

defineExpose({
  submit,
  pending,
  isDisabled,
});
</script>

<template>
  <div class="public-liability">
    <AppFormHeader
      v-if="!isOnboarding"
      :title="title"
      :description="description"
      :loading="pending || isDisabled"
      :show-button="!isPublicLiabilityValid"
      class="mb-5"
      @submit="submit"
    />

    <v-form @submit.prevent="submit">
      <AppFormCard
        title="Public Liability & Indemnity Insurance"
        subtitle="Upload your Public Liability & Indemnity Insurance and date of issue"
        class="mt-4"
        no-gutter
      >
        <RequiredDocumentForm
          ref="publicLiabilityForm"
          :document-type="DocumentType.PUBLIC_LIABILITY"
          :readonly="isPublicLiabilityValid"
          no-border
          @pending="pending = $event"
        />
      </AppFormCard>

      <AppButton
        v-if="isOnboarding"
        block
        color="primary"
        class="mt-4"
        rounded="xl"
        :disabled="isDisabled"
        :loading="pending"
      >
        Save
      </AppButton>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.public-liability {
  width: 100%;
  max-width: var(--form-max-width);
}

.inner-card {
  border: 1px solid #e0e0e0;
}
</style>
